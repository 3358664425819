import { getSetting, watchSetting } from './settings';
import guid, { registerGuid } from './guid';
import { clone } from './object';
import { watchArray, unwatchArray } from './watchArray';

const events = [];
const sourceWatchers = {};
const allWatchers = [];
const pinWatchers = [];
const selectWatchers = [];

export const getEvent = (id) => events.find(({ __id }) => id === __id);

export const getUsedSourceNames = () => {
	const sourceNames = new Set();
	events.forEach(({ __source }) => sourceNames.add(__source));
	return [...sourceNames];
};

export const getAllSourceNames = () => {
	const additional = Object.keys(getSetting('additionalSources') || {});
	return [
		...new Set([
			'ws-tagging',
			...new Set([...getUsedSourceNames(), ...additional])
		])
	];
};

export const getEvents = (sourceName = false) => {
	if (!sourceName) {
		return clone(events);
	} else {
		return clone(events.filter(({ __source }) => __source === sourceName));
	}
};

export const watchSource = (sourceName, callback) => {
	if (!sourceWatchers[sourceName]) sourceWatchers[sourceName] = [];
	sourceWatchers[sourceName].push(callback);
	callback(getEvents(sourceName));
};

export const unwatchSource = (sourceName, callback = false) => {
	if (sourceWatchers[sourceName]) {
		if (callback) {
			const index = sourceWatchers[sourceName].indexOf(callback);
			if (index > -1) {
				sourceWatchers[sourceName].splice(index, 1);
				if (sourceWatchers[sourceName].length === 0) {
					delete sourceWatchers[sourceName];
				}
			}
		} else {
			delete sourceWatchers[sourceName];
		}
	}
};

export const watchAllEvents = (callback) => {
	allWatchers.push(callback);
	callback(clone(events));
};

export const unwatchAllEvents = (callback = false) => {
	if (callback) {
		const index = allWatchers.indexOf(callback);
		if (index > -1) {
			allWatchers.splice(index, 1);
		}
	} else {
		allWatchers.length = 0;
	}
};

export const addEvent = (data, sourceName = 'ws-tagging', isMock = false) => {
	if (Object.keys(data).length === 0) return ''; // dont add empty events
	const event = clone(data);
	if (!event.__id) {
		event.__id = guid();
	} else {
		// If it already has an id let the guid util know
		registerGuid(event.__id);
	}
	if (!event.__timestamp) {
		event.__timestamp = Date.now();
	}
	if (!event.__source) {
		event.__source = sourceName;
	}
	if (!event.__mock) {
		event.__mock = isMock;
	}
	events.push(event);
	const eventClone = clone(event);
	if (sourceWatchers[sourceName]) {
		sourceWatchers[sourceName].forEach((cb) =>
			cb(getEvents(sourceName), eventClone)
		);
	}
	const eventsClone = clone(events);
	allWatchers.forEach((cb) => {
		cb(eventsClone, eventClone);
	});
	return event.__id;
};

export const getPinnedEvents = () =>
	clone(events.filter(({ __pinned }) => !!__pinned));

export const pinEvent = (id) => {
	const event = getEvent(id);
	if (event) {
		event.__pinned = true;
		const pinnedEvents = getPinnedEvents();
		localStorage.setItem(
			'ws-event-viewer-pinned',
			JSON.stringify(pinnedEvents)
		);
		pinWatchers.forEach((cb) => cb(pinnedEvents));
		if (sourceWatchers[event.__source]) {
			const newSourceEvents = getEvents(event.__source);
			sourceWatchers[event.__source].forEach((cb) => cb(newSourceEvents));
		}
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));
	}
};

export const unpinEvent = (id) => {
	const event = getEvent(id);
	if (event) {
		event.__pinned = false;
		const pinnedEvents = getPinnedEvents();
		localStorage.setItem(
			'ws-event-viewer-pinned',
			JSON.stringify(pinnedEvents)
		);
		pinWatchers.forEach((cb) => cb(pinnedEvents));
		if (sourceWatchers[event.__source]) {
			const newSourceEvents = getEvents(event.__source);
			sourceWatchers[event.__source].forEach((cb) => cb(newSourceEvents));
		}
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));
	}
};

export const unpinAllEvents = () => {
	const affectedSources = new Set();
	events.forEach((event) => {
		if (event.__pinned) {
			affectedSources.add(event.__source);
			// eslint-disable-next-line no-param-reassign
			delete event.__pinned;
		}
	});
	localStorage.setItem('ws-event-viewer-pinned', '[]');
	pinWatchers.forEach((cb) => cb([]));
	[...affectedSources].forEach((sourceName) => {
		if (sourceWatchers[sourceName]) {
			const sourceEvents = getEvents(sourceName);
			sourceWatchers[sourceName].forEach((cb) => cb(sourceEvents));
		}
	});
	const eventsClone = clone(events);
	allWatchers.forEach((cb) => cb(eventsClone));
};

export const watchPinnedEvents = (callback) => {
	pinWatchers.push(callback);
	callback(getPinnedEvents());
};

export const unwatchPinnedEvents = (callback = false) => {
	if (callback) {
		const index = pinWatchers.indexOf(callback);
		if (index > -1) pinWatchers.splice(index, 1);
	} else {
		pinWatchers.length = 0;
	}
};

export const clearEvents = (sourceName = false) => {
	if (sourceName) {
		for (let i = 0; i < events.length; i++) {
			const { __source, __pinned } = events[i];
			if (__source === sourceName && !__pinned) {
				events.splice(i, 1);
				i -= 1;
			}
		}
		if (sourceWatchers[sourceName]) {
			const newSourceEvents = getEvents(sourceName);
			sourceWatchers[sourceName].forEach((cb) => cb(newSourceEvents));
		}
	} else {
		for (let i = 0; i < events.length; i++) {
			const { __pinned } = events[i];
			if (!__pinned) {
				events.splice(i, 1);
				i -= 1;
			}
		}
		Object.keys(sourceWatchers).forEach((sn) => {
			const newSourceEvents = getEvents(sn);
			sourceWatchers[sn].forEach((cb) => cb(newSourceEvents));
		});
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));
	}
};

export const getSelectedEvents = () =>
	events.filter(({ __selected }) => !!__selected);

export const selectEvent = (id) => {
	const event = getEvent(id);
	if (event) {
		event.__selected = true;
		const newSelectedEvents = getSelectedEvents();
		selectWatchers.forEach((cb) => cb(newSelectedEvents));
		if (sourceWatchers[event.__source]) {
			const newSourceEvents = getEvents(event.__source);
			sourceWatchers[event.__source].forEach((cb) => cb(newSourceEvents));
		}
		if (event.__pinned) {
			const pinnedEvents = getPinnedEvents();
			localStorage.setItem(
				'ws-event-viewer-pinned',
				JSON.stringify(pinnedEvents)
			);
			pinWatchers.forEach((cb) => cb(pinnedEvents));
		}
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));
	}
};

export const unselectEvent = (id) => {
	const event = getEvent(id);
	if (event) {
		event.__selected = false;
		const selectedEvents = getSelectedEvents();
		selectWatchers.forEach((cb) => cb(selectedEvents));
		if (sourceWatchers[event.__source]) {
			const newSourceEvents = getEvents(event.__source);
			sourceWatchers[event.__source].forEach((cb) => cb(newSourceEvents));
		}
		if (event.__pinned) {
			const pinnedEvents = getPinnedEvents();
			localStorage.setItem(
				'ws-event-viewer-pinned',
				JSON.stringify(pinnedEvents)
			);
			pinWatchers.forEach((cb) => cb(pinnedEvents));
		}
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));
	}
};

export const watchSelectedEvents = (callback) => {
	selectWatchers.push(callback);
	callback(getSelectedEvents());
};

export const unwatchSelectedEvents = (callback = false) => {
	if (callback) {
		const index = selectWatchers.indexOf(callback);
		if (index > -1) selectWatchers.splice(index, 1);
	} else {
		selectWatchers.length = 0;
	}
};

export const mockRerun = (id) => {
	const event = clone(getEvent(id));
	delete event.__id;
	delete event.__timestamp;
	event.__mock = true;
	event.__selected = false;
	event.__pinned = false;
	event.__fromStorage = false;
	addEvent(event);
};

export const deleteEvent = (id) => {
	const i = events.findIndex(({ __id }) => id === __id);
	if (i > -1) {
		const { __source: sn, __pinned, __selected } = events[i];
		events.splice(i, 1); // remove it

		// Let allWatchers know its now gone
		const eventsClone = clone(events);
		allWatchers.forEach((cb) => cb(eventsClone));

		if (sourceWatchers[sn]) {
			// Let the sourceWatchers know its now gone
			const newSourceEvents = getEvents(sn);
			sourceWatchers[sn].forEach((cb) => cb(newSourceEvents));
		}
		if (__pinned) {
			// If it was pinned, let the pinWatchers know its now gone
			const newPinned = getPinnedEvents();
			pinWatchers.forEach((cb) => cb(newPinned));
			// update localstorage
			localStorage.setItem(
				'ws-event-viewer-pinned',
				JSON.stringify(newPinned)
			);
		}
		if (__selected) {
			// If it was selected, let the selectWatchers know its now gone
			const newSelected = getSelectedEvents();
			selectWatchers.forEach((cb) => cb(newSelected));
		}
	}
};

const processEventViewerData = ({ detail }) => {
	const event = {
		...detail?.common,
		...detail?.custom
	};
	addEvent(event, event?.source || 'ws-tagging');
};

if (typeof window !== 'undefined') {
	window.addEventListener('eventViewerData', processEventViewerData);

	window.DDC = window.DDC || {};
	window.DDC.WidgetData = window.DDC.WidgetData || {};
	window.DDC.WidgetData['ws-event-viewer'] =
		window.DDC.WidgetData['ws-event-viewer'] || {};
	window.DDC.WidgetData['ws-event-viewer'].ready = true;
	window.dispatchEvent(new Event('wsEventViewerReady'));
}

export const loadPinned = () => {
	JSON.parse(localStorage.getItem('ws-event-viewer-pinned') || '[]').forEach(
		(event) => {
			addEvent({
				...event,
				__fromStorage: true
			});
		}
	);
};
if (typeof localStorage !== 'undefined') loadPinned();

const watchingPaths = {};
const watchSourcePath = (sourceName, path) => {
	if (!watchingPaths[path]) {
		const cb = (_, event) => {
			addEvent(event, sourceName);
		};
		watchingPaths[path] = cb;
		watchArray(path, cb);
	}
};

const unwatchAllSourcePaths = () => {
	Object.keys(watchingPaths).forEach((path) => {
		const cb = watchingPaths[path];
		delete watchingPaths[path];
		unwatchArray(path, cb);
	});
};

watchSetting('additionalSources', (sources) => {
	unwatchAllSourcePaths();
	Object.keys(sources).forEach((sourceName) => {
		const path = sources[sourceName];
		if (path) {
			watchSourcePath(sourceName, path);
		}
	});
});
