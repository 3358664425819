import { objSame, clone } from './object';

const defaultSettings = {
	showing: false,
	size: {
		width: 550,
		height: 400
	},
	position: {
		top: 16,
		left: 16
	},
	eventObjectsOpenedByDefault: false,
	hideBlankProps: false,
	fullscreen: false,
	order: 'Newest on top',
	promptUnload: false,
	maxEvents: 0,
	additionalSources: [],
	showMetaData: false,
	showAllEvents: false,
	flatView: false,
	filters: [],
	...JSON.parse(
		(typeof window !== 'undefined' &&
			localStorage.getItem('ws-event-viewer-settings')) ||
			'{}'
	)
};

let settings = {
	...defaultSettings
};
const settingsWatchers = new Set();
const settingWatchers = new Map();
export const getSettings = () => clone(settings);
export const getSetting = (name) => clone(settings[name]);
export const watchSettings = (callback) => {
	settingsWatchers.add(callback);
	callback(clone(settings));
};
export const watchSetting = (name, callback) => {
	let watchers = settingWatchers.get(name);
	if (!watchers) {
		watchers = new Set([callback]);
		settingWatchers.set(name, watchers);
	} else {
		watchers.add(callback);
	}
	callback(clone(settings[name]));
};
export const unwatchSettings = (callback) => {
	settingsWatchers.delete(callback);
};
export const unwatchSetting = (name, callback = false) => {
	if (callback) {
		const watchers = settingWatchers.get(name);
		if (watchers) watchers.delete(callback);
	} else {
		settingWatchers.delete(name);
	}
};
export const setSetting = (name, value) => {
	const newSettings = {
		...settings,
		[name]: value
	};
	if (!objSame(settings, newSettings)) {
		settings = newSettings;
		localStorage.setItem(
			'ws-event-viewer-settings',
			JSON.stringify(newSettings)
		);
		settingsWatchers.forEach((callback) => {
			callback(settings);
		});
		const watchers = settingWatchers.get(name);
		if (watchers) {
			watchers.forEach((callback) => {
				callback(value);
			});
		}
	}
};
/*
  The localStorage settings are modfied outside of this module in be beforeEach test, this is used to reload them.
  But can also be used outside of testing if some script ever directly modifies the localStorage settings
*/
export const refreshSettings = () => {
	settings = {
		...defaultSettings
	};
};
