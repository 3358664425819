const prevEvents = {};
const watchers = {};
const timers = {};
const getGlobalVar = (path) => {
	const parts = path.split('.');
	try {
		let temp = window || global;
		while (parts.length > 0) {
			const part = parts.shift();
			temp = temp[part];
		}
		return temp;
	} catch (e) {
		return [];
	}
};
export const watchArray = (path, callback) => {
	if (!watchers[path]) watchers[path] = [];
	watchers[path].push(callback);
	if (!timers[path]) {
		timers[path] = setInterval(() => {
			if (!prevEvents[path]) prevEvents[path] = [];
			const array = getGlobalVar(path) || [];
			if (array instanceof Array) {
				array.forEach((event) => {
					if (!prevEvents[path].includes(event)) {
						watchers[path].forEach((cb) => cb(array, event));
						prevEvents[path].push(event);
					}
				});
			}
		}, 100);
	}
};
export const unwatchArray = (path, callback = false) => {
	if (!callback) {
		delete watchers[path];
		delete prevEvents[path];
		clearInterval(timers[path]);
	} else if (watchers[path]) {
		const index = watchers[path].indexOf(callback);
		if (index > -1) {
			watchers[path].splice(index, 1);
			if (!watchers[path].length) {
				delete watchers[path];
				delete prevEvents[path];
				clearInterval(timers[path]);
			}
		}
	}
};
