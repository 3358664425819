const guids = new Set();
export default () => {
	let guid;
	do {
		guid = (Math.random() * 99999).toString(16).replace('.', '');
	} while (guids.has(guid));
	guids.add(guid);
	return guid;
};

export const registerGuid = (id) => {
	guids.add(id);
};
