import { connect } from 'react-redux';
import React from 'react';
import '../utils/events';

const eventViewerInitialState = (win) => {
	let show = false;

	if (
		new URL(win.location).searchParams.get('_toggleEventViewer') === 'false'
	) {
		show = false;
	} else if (win.localStorage.getItem('eventViewer') === 'true') {
		show = true;
	} else if (
		new URL(win.location).searchParams.get('_toggleEventViewer') === 'true'
	) {
		show = true;
		window.localStorage.setItem('eventViewer', 'true');
	}

	return show;
};

export class WidgetContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			eventViewer: false,
			EventViewerComp: false
		};
		this.handleKeydown = this.handleKeydown.bind(this);
	}

	componentDidMount() {
		this.setState(() => ({
			eventViewer: eventViewerInitialState(window)
		}));

		window.addEventListener('keydown', this.handleKeydown);
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeydown);
	}

	handleKeydown({ altKey, ctrlKey, metaKey, shiftKey, key }) {
		if (
			altKey &&
			ctrlKey &&
			metaKey &&
			shiftKey &&
			['∆', 'Î'].includes(key)
		) {
			this.setState(
				({ eventViewer }) => ({
					eventViewer: !eventViewer
				}),
				() => {
					window.localStorage.setItem(
						'eventViewer',
						this.state.eventViewer
					);
				}
			);
		}
	}

	render() {
		if (!this.state.EventViewerComp && this.state.eventViewer) {
			(async () => {
				this.setState({
					EventViewerComp: (await import('../components/EventViewer'))
						.default
				});
			})();
		}
		return (
			<React.Fragment>
				{this.state.EventViewerComp && this.state.eventViewer && (
					<this.state.EventViewerComp
						data={this.state.eventViewerData}
						clearEventViewer={this.clearEventViewer}
					/>
				)}
			</React.Fragment>
		);
	}
}

WidgetContainer.propTypes = {};

const mapStateToProps = (state) => {
	return {
		mapping: state.mapping,
		legacyConfigurations: state.legacyConfigurations,
		flags: state.flags
	};
};

const mapDispatchToProps = (_dispatch) => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
